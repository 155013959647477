<template>
  <div
    class="fixed top-2 cursor-pointer"
    :class="{'left-3': !menuIsOpen, 'z-30': menuIsOpen }"
    @click="openMenu()"
  >
    <img
      v-if="!menuIsOpen"
      src="../../../public/img/app-images/menu_18dp_gray50.svg"
      class="h-7 w-7 transform translate-x-0 transition-transform duration-300 ease-in hover:scale-125 -rotate-180"
    >

    <img
      v-else-if="online"
      src="../../../public/img/app-images/double_arrow_18dp_gray50.svg"
      class="h-7 w-7 ml-4 transform translate-x-60 transition-transform duration-300 ease-in hover:scale-125 rotate-180"
    >
  </div>
</template>

<script>
export default {
  computed: {
    menuIsOpen () {
      return this.$store.getters.menuIsOpen
    },
    online() {
      return this.$store.getters.online
    }
  },
  methods: {
    openMenu () {
      this.$store.commit('setMenuIsOpen', !this.menuIsOpen)
    }
  }
}
</script>