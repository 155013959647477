import { createRouter, createWebHistory } from 'vue-router'
import { getAuth } from 'firebase/auth'
import store from '../store'
import { setPageTitle } from '@/helpers/page-meta'

const routes = [
  {
    path: "/",
    name: "Home",
    meta: { layout: 'public', title: '' },
    component: () => import('../views/Home.vue')
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { layout: 'private', auth: true, toTop: true, smoothScroll: true, title: 'Dashboard' },
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: "/errors",
    name: "AppErrors",
    meta: { layout: 'private', auth: true, toTop: true, smoothScroll: true, title: 'App Errors' },
    component: () => import('../views/AppErrors.vue')
  },
  {
    path: "/users",
    name: "UsersView",
    meta: { layout: 'private', auth: true, toTop: true, smoothScroll: true, title: 'Users' },
    component: () => import('../views/UsersView.vue')
  },
  {
    path: "/feedback",
    name: "Feedback",
    meta: { layout: 'private', auth: true, toTop: true, smoothScroll: true, title: 'Feedback' },
    component: () => import('../views/Feedback.vue')
  },
  {
    path: "/news",
    name: "News",
    meta: { layout: 'private', auth: true, toTop: true, smoothScroll: true, title: 'News' },
    component: () => import('../views/News.vue')
  },
  {
    path: "/delete-users",
    name: "DeleteUsers",
    meta: { layout: 'private', auth: true, toTop: true, smoothScroll: true, title: 'Delete Users' },
    component: () => import('../views/DeleteUsers.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'public', title: 'Authorization' },
    component: () => import('../views/Login.vue')
  },
  { path: '/:pathMatch(.*)*', redirect: '/' }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    const scroll = {}
    if (to.meta.toTop) { scroll.top = 0 }
    if (to.meta.smoothScroll) { scroll.behavior = 'smooth' }
    return scroll
  }
})

router.beforeEach((to, from, next) => {
  setPageTitle(to.meta.title)

  const currentUser = getAuth().currentUser
  const requireAuth = to.matched.some(record => record.meta.auth)

  if (requireAuth && !currentUser) {
    store.commit('setOpenedLink', to.fullPath)
    next('/login?message=firstlogin')
  } else {
    next()
  }
})

export default router;
