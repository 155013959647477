<template>
  <div 
    class="min-h-screen min-w-min h-auto inset-0 bg-gradient-to-r from-amber-300 via-red-300 to-pink-400"
  >
    <div
      v-if="loading"
      class="flex justify-center h-screen"
    >
      <Loader />
    </div>

    <div v-else>
      <MenuToggle />

      <Drawer />

      <main>
        <span 
          v-if="firebaseQuotaReached"
          class="flex justify-center pt-20 text-gray-700 text-xl"
        >
          Приложение временно недоступно.<br>
          Попробуйте заглянуть позднее.
        </span>

        <span v-else>
          <router-view />
        </span>
      </main>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Drawer from '@/components/navigation/Drawer'
import MenuToggle from '@/components/navigation/MenuToggle'
import Loader from '@/components/UI/loaders/Loader'

export default {
  name: 'PrivateLayout',
  components: {
    Drawer, MenuToggle, Loader
  },
  data: () => ({
    loading: true
  }),
  computed: {
    ...mapGetters([
      'firebaseQuotaReached',
      'closeAllMenus',
      'isAdmin'
    ])
  },
  watch: {
    closeAllMenus (value) {
      if (value) {
        this.$store.commit('setMenuIsOpen', false)
      }
    },
    isAdmin (value) {
      if (!value) {
        this.$router.push({ name: 'Login', query: { message: 'reauth' } }).catch(() => {})
      }
    }
  },
  async created() {
    this.loading = true

    const uid = await this.$store.dispatch('getUid')

    if (!this.isAdmin || !uid) {
      this.$router.push({ name: 'Login', query: { message: 'reauth' } }).catch(() => {})
      return
    }

    if (Object.keys(this.$store.getters.tarifs).length <= 0) {
      await this.$store.dispatch('fetchTarifs')
    }

    await this.$store.dispatch('subscribeToMaintenance')
    await this.$store.dispatch('subscribeDeleteUsers')
    await this.$store.dispatch('subscribeToAppErrors')
    await this.$store.dispatch('subscribeToFeedback')
    await this.$store.dispatch('subscribeToNumberOfUsersTotal')

    this.loading = false
  },
  mounted() {
    document.addEventListener('keydown', this.clickedButton)
  },
  async beforeUnmount() {
    document.removeEventListener('keydown', this.clickedButton)

    await this.$store.dispatch('unsubscribeIfAdmin')
    await this.$store.dispatch('unsubscribeMaintenance')
    await this.$store.dispatch('unsubscribeDeleteUsers')
    await this.$store.dispatch('unsubscribeToAppErrors')
    await this.$store.dispatch('unsubscribeToFeedback')
    await this.$store.dispatch('unsubscribeToNumberOfUsersTotal')
    
    this.$store.commit('clearInfo')
  },
  methods: {
    clickedButton (event) {
      if (!event) { return }

      if (event.code === 'Escape') {
        this.$store.commit('setCloseAllMenus')
        return
      }
    }
  }
}
</script>
