import { getFirestore, doc, collection, onSnapshot, writeBatch } from 'firebase/firestore'
import packageData from './../../package.json'

export default {
  state: {
    appVersion: packageData.version,
    firebaseQuotaReached: false,
    online: true,
    closeAllMenus: false,
    menuIsOpen: false,
    maintenance: false,
    maintenanceUnsubscribe: null,
    openedLink: null
  },
  mutations: {
    setOnline(state, status) {
      state.online = status
    },
    setCloseAllMenus(state) {
      state.closeAllMenus = true
      setTimeout(() => { state.closeAllMenus = false })
    },
    setMenuIsOpen(state, value) {
      state.menuIsOpen = value
    },
    setMaintenance(state, data) {
      if (data && data.toDate) { data = data.toDate() }
      state.maintenance = data
    },
    setMaintenanceUnsubscribe(state, value) {
      state.maintenanceUnsubscribe = value
    },
    setOpenedLink(state, value) {
      state.openedLink = value
    },
    clearInfo(state) {
      state.online = true
      state.firebaseQuotaReached = false
      state.closeAllMenus = false
      state.menuIsOpen = false
      state.maintenance = false
      state.maintenanceUnsubscribe = null
    }
  },
  actions: {
    async subscribeToMaintenance({ commit, dispatch }) {
      try {
        const ref = doc(getFirestore(), 'settings/maintenance')

        const unsubscribe = onSnapshot(ref, async maintenance => {
          if (maintenance.exists()) {
            const data = maintenance.data()
            await commit('setMaintenance', data.closed)
          }
          await commit('setMaintenanceUnsubscribe', unsubscribe)
        })
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:subscribeToMaintenance', params: {} })
        return false
      }
    },
    async unsubscribeMaintenance({ dispatch, getters }) {
      try {
        const maintenanceUnsubscribe = getters.maintenanceUnsubscribe
        if (maintenanceUnsubscribe) {
          await maintenanceUnsubscribe()
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:unsubscribeMaintenance', params: {} })
        return false
      }
    },
    async planMaintenance({ commit, dispatch }, time) {
      if (!time) {
        commit('setError', 'Ошибка')
        return false
      }

      const syncTimestamp = new Date()
      const uid = await dispatch('getUid')

      const maintenanceDate = new Date(+new Date() + (time * 60 * 1000))

      try {
        const batch = writeBatch(getFirestore())

        const maintenanceRef = doc(getFirestore(), 'settings/maintenance')
        batch.update(maintenanceRef, { closed: maintenanceDate })

        const logId = doc(collection(getFirestore(), `logs`)).id
        const logRef = doc(getFirestore(), `logs/${logId}`)

        batch.set(logRef, {
          timestamp: syncTimestamp,
          place: 'settings',
          action: 'planMaintenance',
          type: 'admin',
          owner: uid,
          id: uid,
          data: {
            time: time
          }
        })

        await batch.commit()

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:planMaintenance', params: { time: time } })
        return false
      }
    },
    async switchOnMaintenance({ dispatch }) {
      const syncTimestamp = new Date()
      const uid = await dispatch('getUid')

      try {
        const batch = writeBatch(getFirestore())

        const maintenanceRef = doc(getFirestore(), 'settings/maintenance')
        batch.update(maintenanceRef, { closed: true })

        const logId = doc(collection(getFirestore(), `logs`)).id
        const logRef = doc(getFirestore(), `logs/${logId}`)

        batch.set(logRef, {
          timestamp: syncTimestamp,
          place: 'settings',
          action: 'switchMaintenance',
          type: 'admin',
          owner: uid,
          id: uid,
          data: {
            maintenance: 'on'
          }
        })

        await batch.commit()

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:switchOnMaintenance', params: {} })
        return false
      }
    },
    async switchOffMaintenance({ dispatch }) {
      const syncTimestamp = new Date()
      const uid = await dispatch('getUid')

      try {
        const batch = writeBatch(getFirestore())

        const maintenanceRef = doc(getFirestore(), 'settings/maintenance')
        batch.update(maintenanceRef, { closed: false })

        const logId = doc(collection(getFirestore(), `logs`)).id
        const logRef = doc(getFirestore(), `logs/${logId}`)

        batch.set(logRef, {
          timestamp: syncTimestamp,
          place: 'settings',
          action: 'switchMaintenance',
          type: 'admin',
          owner: uid,
          id: uid,
          data: {
            maintenance: 'off'
          }
        })

        await batch.commit()

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:switchOffMaintenance', params: {} })
        return false
      }
    }
  },
  getters: {
    firebaseQuotaReached: s => s.firebaseQuotaReached,
    online: s => s.online,
    closeAllMenus: s => s.closeAllMenus,
    menuIsOpen: s => s.menuIsOpen,
    appVersion: s => s.appVersion,
    maintenance: s => s.maintenance,
    maintenanceUnsubscribe: s => s.maintenanceUnsubscribe,
    openedLink: s => s.openedLink
  }
}
