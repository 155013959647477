import { createStore } from 'vuex'

import app from './app'
import analytics from './analytics'
import appErrors from './app-errors'
import auth from './auth'
import dashboard from './dashboard'
import errors from './errors'
import feedback from './feedback'
import news from './news'
import tarifs from './tarifs'
import users from './users'

export default createStore({
  modules: {
    app, analytics, appErrors, auth, dashboard, errors, feedback, news, tarifs, users
  }
})
