import { getAuth, signInWithEmailAndPassword, signOut, setPersistence, browserSessionPersistence } from 'firebase/auth'
import { getFirestore, doc, getDoc, setDoc, collection, onSnapshot } from 'firebase/firestore'

export default {
  state: {
    isAdmin: false,
    isAdminUnsubscribe: null
  },
  mutations: {
    setIsAdmin(state, status) {
      state.isAdmin = status
    },
    setIsAdminUnsubscribe(state, value) {
      state.isAdminUnsubscribe = value
    },
    clearInfo(state) {
      state.isAdmin = false
      state.isAdminUnsubscribe = null
    }
  },
  actions: {
    getUid() {
      const user = getAuth().currentUser
      return user ? user.uid : null
    },
    async login({ commit, dispatch }, { email, password }) {
      await commit('clearInfo')

      if (!email || !password) {
        commit('setError', 'Ошибка')
        return false
      }

      try {
        const auth = getAuth()

        await setPersistence(auth, browserSessionPersistence)

        await signInWithEmailAndPassword(auth, email, password)

        const uid = await dispatch('getUid')
        const syncTimestamp = new Date()

        if (getAuth().currentUser) {
          const logId = doc(collection(getFirestore(), `logs`)).id
          const logRef = doc(getFirestore(), `logs/${logId}`)

          await setDoc(logRef, {
            timestamp: syncTimestamp,
            place: 'auth',
            action: 'login',
            type: 'admin',
            owner: uid,
            id: uid,
            data: {
              navigatorLanguage: navigator.language,
              navigatorLanguages: navigator.languages,
              navigatorUserAgent: navigator.userAgent,
            }
          })
        }

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:login', params: { email } })
      }
    },
    async logout({ commit, dispatch }) {
      try {
        await dispatch('unsubscribeIfAdmin')
        await dispatch('unsubscribeMaintenance')
        await dispatch('unsubscribeToAppErrors')
        await dispatch('unsubscribeToNumberOfUsersTotal')
        await dispatch('unsubscribeToFeedback')
        await dispatch('unsubscribeDeleteUsers')

        const uid = await dispatch('getUid')

        if (uid) {
          const logId = doc(collection(getFirestore(), `logs`)).id
          const logRef = doc(getFirestore(), `logs/${logId}`)

          await setDoc(logRef, {
            timestamp: new Date(),
            place: 'auth',
            action: 'logout',
            type: 'admin',
            owner: uid,
            id: uid,
            data: {}
          }).catch((e) => { console.log(e) })

          await signOut(getAuth())
        }

        await commit('clearInfo')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:logout', params: {} })
        return false
      }
    },
    async checkIfAdmin({ commit, dispatch }) {
      try {
        const uid = await dispatch('getUid')

        const ref = doc(getFirestore(), `admins/${uid}`)
        await getDoc(ref).then(admin => {
          if (admin.exists() && admin.data().isAdmin) {
            commit('setIsAdmin', admin.data().isAdmin)

            const unsubscribe = onSnapshot(ref, async doc => {
              if (doc.exists()) {
                await commit('setIsAdmin', doc.data().isAdmin)
              } else {
                await commit('setIsAdmin', false)
              }
              await commit('setIsAdminUnsubscribe', unsubscribe)
            }, error => {
              console.log(error)
            })
          } else {
            commit('setIsAdmin', false)
          }
        })
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:checkIfAdmin', params: {} })
        return false
      }
    },
    async unsubscribeIfAdmin({ dispatch, getters }) {
      try {
        if (getters.isAdminUnsubscribe) {
          const isAdminUnsubscribe = getters.isAdminUnsubscribe
          await isAdminUnsubscribe()
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:unsubscribeIfAdmin', params: {} })
        return false
      }
    }
  },
  getters: {
    isAdmin: s => s.isAdmin,
    isAdminUnsubscribe: s => s.isAdminUnsubscribe
  }
}