import { getFirestore, doc, collection, getDocs, limit, orderBy, where, query, startAfter, writeBatch } from 'firebase/firestore'

export default {
  state: {
    news: {},
    newsReset: 0,
    allNewsFetched: false,
    lastFetchedNews: null
  },
  mutations: {
    setNews(state, newsInfo) {
      if (newsInfo && newsInfo.newsId) {
        if (newsInfo.timestamp && newsInfo.timestamp.toDate) {
          newsInfo.timestamp = newsInfo.timestamp.toDate()
        }
        state.news[newsInfo.newsId] = newsInfo
      }
    },
    deleteNewsFromStore(state, newsId) {
      if (newsId && state.news[newsId]) {
        delete state.news[newsId]
      }
    },
    resetNews(state) {
      state.newsReset = +new Date()
    },
    setLastFetchedNews(state, news) {
      state.lastFetchedNews = news
    },
    setAllNewsFetched(state) {
      state.allNewsFetched = true
    }
  },
  actions: {
    async fetchNews({ commit, dispatch, getters }, userId) {
      if (userId) {
        if (getters.users[userId] && getters.users[userId].allNewsFetched) { return }
      } else {
        if (getters.allNewsFetched) { return }
      }

      const maxLength = 10

      try {
        const queries = [limit(maxLength), orderBy('timestamp', 'desc')]

        let lastFetchedNews
        if (userId) {
          queries.push(where('user', '==', userId))

          if (getters.users[userId] && getters.users[userId].lastFetchedNews) {
            lastFetchedNews = getters.users[userId].lastFetchedNews
          }
        } else {
          lastFetchedNews = getters.lastFetchedNews
        }

        if (lastFetchedNews) {
          queries.push(startAfter(lastFetchedNews))
        }

        const newsRef = collection(getFirestore(), 'news')
        const q = query(newsRef, ...queries)
        await getDocs(q)
          .then(async news => {
            news.forEach(async oneNews => {
              let data = oneNews.data()

              if (data.timestamp && data.timestamp.toDate) { data.timestamp = data.timestamp.toDate() }

              data.newsId = oneNews.id
              await commit('setNews', data)
            })

            if (userId) {
              const data = {
                userId,
                news: news.docs[news.docs.length - 1]
              }
              await commit('setLastFetchedUserNews', data)
            } else {
              await commit('setLastFetchedNews', news.docs[news.docs.length - 1])
            }

            if (news.empty || news.size < maxLength) {
              if (userId) {
                await commit('setAllUserNewsFetched', userId)
              } else {
                await commit('setAllNewsFetched')
              }
            }

            commit('resetNews')
          })
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:fetchNews', params: { userId } })
      }
    },
    async addMessage({ commit, dispatch }, data) {
      if (!data || !data.text || !data.subject || !data.user) {
        commit('setError', 'Ошибка')
        return false
      }

      const uid = await dispatch('getUid')

      data.timestamp = new Date(+new Date() + 5000)
      data.creator = uid

      try {
        const batch = writeBatch(getFirestore())

        const newsId = doc(collection(getFirestore(), 'news')).id
        const newsRef = doc(getFirestore(), `news/${newsId}`)

        batch.set(newsRef, data)

        await batch.commit()
          .then(async () => {
            data.newsId = newsId
            await commit('setNews', data)
            commit('resetNews')
          })

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:addMessage', params: { data } })
        return false
      }
    },
    async deleteMessage({ commit, dispatch, getters }, { newsId, userId }) {
      if (!newsId || !userId || !getters.news[newsId]) {
        commit('setError', 'Ошибка')
        return false
      }

      const uid = await dispatch('getUid')

      const syncTimestamp = new Date()

      try {
        const batch = writeBatch(getFirestore())

        const newsRef = doc(getFirestore(), `news/${newsId}`)

        batch.delete(newsRef)

        if (userId.indexOf('language:') < 0 && userId.indexOf('country:') < 0) {
          const newsLogId = doc(collection(getFirestore(), `logs`)).id
          const newsLogRef = doc(getFirestore(), `logs/${newsLogId}`)

          batch.set(newsLogRef, {
            timestamp: syncTimestamp,
            place: 'news',
            action: 'deleted',
            type: 'sync',
            owner: userId,
            id: newsId,
            data: {
              admin: uid,
            }
          })
        }

        await batch.commit()
          .then(async () => {
            await commit('deleteNewsFromStore', newsId)
            commit('resetNews')
          })

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:deleteMessage', params: { newsId, userId } })
        return false
      }
    }
  },
  getters: {
    news: s => s.news,
    newsArr: (s, getters) => (userId, chosenLanguage, chosenCountry) => {
      if (getters.newsReset) {
        //
      }

      let answer = []

      const allNews = Object.keys(s.news)
      if (allNews.length) {
        answer = allNews.slice(0)
      }

      if (userId) {
        answer = answer.filter(newsId => {
          return s.news[newsId].user === userId
        })
      } else {
        if (chosenLanguage) {
          answer = answer.filter(newsId => {
            let locale
            if (s.news[newsId].user.includes('language:')) {
              locale = s.news[newsId].user.substr(9, s.news[newsId].user.length - 1)
            }
            return locale === chosenLanguage
          })
        }

        if (chosenCountry) {
          answer = answer.filter(newsId => {
            let locale
            if (s.news[newsId].user.includes('country:')) {
              locale = s.news[newsId].user.substr(8, s.news[newsId].user.length - 1)
            }
            return locale === chosenCountry
          })
        }
      }

      if (answer.length > 1) {
        answer.sort((a, b) => {
          let newsA = s.news[a].timestamp ? +s.news[a].timestamp : 0
          let newsB = s.news[b].timestamp ? +s.news[b].timestamp : 0

          if (newsA > newsB) { return -1 }
          if (newsA < newsB) { return 1 }
          return 0
        })
      }

      return answer
    },
    newsReset: s => s.newsReset,
    allNewsFetched: s => s.allNewsFetched,
    lastFetchedNews: s => s.lastFetchedNews
  }
}
