import { createApp } from 'vue'
import App from "./App.vue"
import router from "./router"
import store from "./store"

import dateFilter from '@/filters/date.filter'

import 'tailwindcss/tailwind.css'

import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

function checkDomain() {
  if (process.env.NODE_ENV === 'production') {
    if (top.document.location.hostname !== document.location.hostname) {
      document.location.replace(process.env.VUE_APP_APP_URL)
    }
  }
}

checkDomain()

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line
  globalThis.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = true
}

const firebaseApp = initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_FIREBASE_APPID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASURMENTID
})

getAnalytics(firebaseApp)

let app

const auth = getAuth(firebaseApp)
onAuthStateChanged(auth, () => {
  if (!app) {
    app = createApp(App)
    app.use(router)
    app.use(store)

    app.config.globalProperties.$filters = {
      dateFilter
    }

    app.mount('#app')
    logEvent(getAnalytics(), 'appCreatedADMIN')
  }
})