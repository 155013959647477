import { getFirestore, doc, onSnapshot, getDocs, query, collection, getDoc, where } from 'firebase/firestore'
import { prepareDateFields } from '@/utils/prepareDateFields'

export default {
  state: {
    numberOfUsersTotal: null,
    unsubscribeToNumberOfUsersTotal: null
  },
  mutations: {
    setNumberOfUsersTotal(state, number) {
      state.numberOfUsersTotal = number
    },
    setUnsubscribeToNumberOfUsersTotal(state, value) {
      state.unsubscribeToNumberOfUsersTotal = value
    },
    clearInfo(state) {
      state.numberOfUsersTotal = null
      state.unsubscribeToNumberOfUsersTotal = null
    }
  },
  actions: {
    async subscribeToNumberOfUsersTotal({ commit, dispatch }) {
      try {
        const ref = doc(getFirestore(), 'stats/users')

        const unsubscribe = onSnapshot(ref, async totalNumberOfUsers => {
          if (totalNumberOfUsers.exists()) {
            commit('setNumberOfUsersTotal', totalNumberOfUsers.data().numberOfUsersTotal)
          }
        })

        await commit('setUnsubscribeToNumberOfUsersTotal', unsubscribe)
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:subscribeToNumberOfUsersTotal', params: {} })
      }
    },
    async unsubscribeToNumberOfUsersTotal({ dispatch, getters }) {
      try {
        const unsubscribeToNumberOfUsersTotal = getters.unsubscribeToNumberOfUsersTotal
        if (unsubscribeToNumberOfUsersTotal) {
          await unsubscribeToNumberOfUsersTotal()
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:unsubscribeToNumberOfUsersTotal', params: {} })
        return false
      }
    },
    async exportDB({ dispatch }, { userId = null, exportLogs = false }) {
      try {
        console.log(`Export started${userId ? (' for userId: ' + userId) : ''}${exportLogs ? ' (with logs)' : ''}`)

        const startedAt = Date.now()

        let blocks = [
          { name: 'admins', notForOneUserExport: true },
          { name: 'settings', notForOneUserExport: true },
          { name: 'stats', notForOneUserExport: true },
          { name: 'deleteUsers' },
          { name: 'deletedUsers' },
          { name: 'userDefaultAccount' },
          { name: 'userProfile' },
          { name: 'userSettings' },
          { name: 'userStats' },
          { name: 'userTarif' },
          { name: 'accounts', userIdField: 'owner' },
          { name: 'banks', userIdField: 'owner' },
          { name: 'cards', userIdField: 'owner' },
          { name: 'errors', userIdField: 'owner' },
          { name: 'feedback', userIdField: 'owner' },
          { name: 'moneyBoxes', userIdField: 'owner' },
          { name: 'news', userIdField: 'user' },
          { name: 'payments', userIdField: 'owner' },
          { name: 'spreads', userIdField: 'owner' },
          { name: 'tags', userIdField: 'owner' },
          { name: 'tagsCollections', userIdField: 'owner' },
          { name: 'tagsSums', userIdField: 'owner' },
          { name: 'tarifs', userIdField: 'owner' },
          { name: 'transactions', userIdField: 'owner' },
          { name: 'logs', userIdField: 'owner' },
        ]

        const answer = {
          timestamp: new Date()
        }

        if (userId) {
          answer.userId = userId
          blocks = blocks.filter(block => { return !block.notForOneUserExport })
        }
        if (!exportLogs) { blocks = blocks.filter(block => { return block.name !== 'logs' }) }

        for (let i = 0; i < blocks.length; i++) {
          blocks[i].idx = i + 1
        }

        for (const block of blocks) {
          if (!block.name) {
            console.log('!!! Not found: block.name ==>')
            console.log(block)
            continue
          }

          answer[block.name] = {}

          console.log(`  exporting ${block.name} (${block.idx}/${blocks.length})`)

          if (userId && !block.userIdField) {
            const receivedData = await getDoc(doc(getFirestore(), `${block.name}/${userId}`))
            if (receivedData && receivedData.exists()) {
              answer[block.name] = prepareDateFields(receivedData.data())
            }
          } else {
            const request = []
            if (userId && block.userIdField) { request.push(where(block.userIdField, '==', userId)) }

            const blockRef = collection(getFirestore(), block.name)
            const blockQ = query(blockRef, ...request)
            const receivedBlock = await getDocs(blockQ)

            if (!receivedBlock || receivedBlock.empty) { continue }

            console.log(`    size: ${receivedBlock.size}`)

            for (const oneDoc of receivedBlock.docs) {
              console.log(`      id: ${oneDoc.id}`)

              answer[block.name][oneDoc.id] = prepareDateFields(oneDoc.data())

              if (block.name === 'logs' && answer[block.name][oneDoc.id].data && answer[block.name][oneDoc.id].data.info) {
                answer[block.name][oneDoc.id].data.info = prepareDateFields(answer[block.name][oneDoc.id].data.info)
              }
            }
          }
        }

        console.log(`Export finished in ${(Date.now() - startedAt) / 1000} seconds`)

        return answer
      } catch (e) {
        if (!userId) { userId = null }
        if (!exportLogs) { userId = false }
        dispatch('saveErrorInfo', { error: e, location: 'admin:exportDB', params: { userId, exportLogs } })
      }
    }
  },
  getters: {
    numberOfUsersTotal: s => s.numberOfUsersTotal,
    unsubscribeToNumberOfUsersTotal: s => s.unsubscribeToNumberOfUsersTotal
  }
}
