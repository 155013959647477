<template>
  <div id="app">
    <component :is="layout" />
  </div>

  <AppNotifications />
</template>

<script>
import AppNotifications from '@/components/UI/AppNotifications'
import useNotifications from '@/composables/useNotifications'
import PublicLayout from '@/layouts/PublicLayout'
import PrivateLayout from '@/layouts/PrivateLayout'

export default {
  components: {
    AppNotifications, PublicLayout, PrivateLayout
  },
  setup () {
    const { toastify } = useNotifications()
    return { toastify }
  },
  data: () => ({
    onlineToastId: null
  }),
  computed: {
    layout () {
      return (this.$route.meta.layout || 'public') + '-layout'
    },
    error () {
      return this.$store.getters.error
    },
    online () {
      return this.$store.getters.online
    }
  },
  watch: {
    error (fbError) {
      if(fbError) {
        this.toastify.error(fbError)
        this.$store.commit('clearError')
      }
    },
    online (value) {
      this.removeOnlineToast()

      if (value) {
        this.onlineToastId = this.toastify.success('Доступ к сети восстановлен')
      } else {
        this.onlineToastId = this.toastify.error('Нет доступа к интернету', { timeout: null, eternal: true })
      }
    }
  },
  created () {
    this.$store.dispatch('logout')
  },
  mounted() {
    window.addEventListener('online', this.handleOnlineStatus)
    window.addEventListener('offline', this.handleOnlineStatus)
  },
  async beforeUnmount () {
    window.removeEventListener('online', this.handleOnlineStatus)
    window.removeEventListener('offline', this.handleOnlineStatus)
  },
  methods: {
    handleOnlineStatus () {
      this.$store.commit('setOnline', navigator.onLine)
    },
    removeOnlineToast() {
      this.toastify.remove(this.onlineToastId)
    }
  }
}
</script>