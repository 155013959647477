import { getFirestore, collection, doc, getDocs, query, where, limit, orderBy, startAfter, writeBatch, onSnapshot } from 'firebase/firestore'

export default {
  state: {
    appErrors: {},
    allArchievedAppErrorsFetched: false,
    lastFetchedArchievedError: null,
    appErrorsReset: 0,
    unsubscribeToAppErrors: null
  },
  mutations: {
    setAppError(state, errorInfo) {
      if (errorInfo && errorInfo.errorId) {
        if (errorInfo.timestamp && errorInfo.timestamp.toDate) {
          errorInfo.timestamp = errorInfo.timestamp.toDate()
        }
        state.appErrors[errorInfo.errorId] = errorInfo
      }
    },
    setUnsubscribeToAppErrors(state, value) {
      state.unsubscribeToAppErrors = value
    },
    resetAppErrors(state) {
      state.appErrorsReset = +new Date()
    },
    setErrorDone(state, data) {
      if (data && data.errorId && state.appErrors[data.errorId]) {
        state.appErrors[data.errorId].active = false
        state.appErrors[data.errorId].reviewedBy = data.reviewedBy
        state.appErrors[data.errorId].reviewed = data.reviewed
      }
    },
    setLastFetchedArchievedError(state, error) {
      state.lastFetchedArchievedError = error
    },
    setAllArchievedAppErrorsFetched(state) {
      state.allArchievedAppErrorsFetched = true
    },
    clearInfo(state) {
      state.appErrors = {}
      state.allArchievedAppErrorsFetched = false
      state.lastFetchedArchievedError = null
      state.unsubscribeToAppErrors = null
    }
  },
  actions: {
    async subscribeToAppErrors({ commit, dispatch }) {
      try {
        const ref = collection(getFirestore(), 'errors')
        const q = query(ref, where('active', '==', true), orderBy('timestamp', 'desc'))

        const unsubscribe = onSnapshot(q, async querySnapshot => {
          querySnapshot.docChanges().forEach(async change => {
            const data = change.doc.data()
            data.errorId = change.doc.id
            if (change.type === 'added' || change.type === 'modified') {
              await commit('setAppError', data)
            } else if (change.type === 'removed') {
              data.active = false
              await commit('setAppError', data)
            }
          })

          commit('resetAppErrors')
        })

        await commit('setUnsubscribeToAppErrors', unsubscribe)
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:subscribeToAppErrors', params: {} })
      }
    },
    async unsubscribeToAppErrors({ dispatch, getters }) {
      try {
        const unsubscribeToAppErrors = getters.unsubscribeToAppErrors
        if (unsubscribeToAppErrors) {
          await unsubscribeToAppErrors()
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:unsubscribeToAppErrors', params: {} })
        return false
      }
    },
    async fetchArchievedAppErrors({ commit, dispatch, getters }, userId) {
      if (userId) {
        if (getters.users[userId] && getters.users[userId].allArchievedAppErrorsFetched) { return }
      } else {
        if (getters.allArchievedAppErrorsFetched) { return }
      }

      const maxLength = 10

      try {
        const queries = [limit(maxLength), orderBy('timestamp', 'desc'), where('active', '==', false)]

        let lastFetchedArchievedError
        if (userId) {
          queries.push(where('owner', '==', userId))

          if (getters.users[userId] && getters.users[userId].lastFetchedArchievedError) {
            lastFetchedArchievedError = getters.users[userId].lastFetchedArchievedError
          }
        } else {
          lastFetchedArchievedError = getters.lastFetchedArchievedError
        }

        if (lastFetchedArchievedError) {
          queries.push(startAfter(lastFetchedArchievedError))
        }

        const errorsRef = collection(getFirestore(), 'errors')
        const q = query(errorsRef, ...queries)
        await getDocs(q)
          .then(async errors => {
            errors.forEach(async error => {
              let data = error.data()

              if (data.timestamp && data.timestamp.toDate) { data.timestamp = data.timestamp.toDate() }
              if (data.reviewed && data.reviewed.toDate) { data.reviewed = data.reviewed.toDate() }

              data.errorId = error.id
              await commit('setAppError', data)
            })

            if (userId) {
              const data = {
                userId,
                error: errors.docs[errors.docs.length - 1]
              }
              await commit('setLastFetchedArchievedUserError', data)
            } else {
              await commit('setLastFetchedArchievedError', errors.docs[errors.docs.length - 1])
            }

            if (errors.empty || errors.size < maxLength) {
              if (userId) {
                await commit('setAllArchievedUserAppErrorsFetched', userId)
              } else {
                await commit('setAllArchievedAppErrorsFetched')
              }
            }

            commit('resetAppErrors')
          })
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:fetchArchievedAppErrors', params: { userId: userId } })
      }
    },
    async errorDone({ commit, dispatch, getters }, errorId) {
      if (!errorId || !getters.appErrors[errorId]) {
        commit('setError', 'Ошибка')
        return false
      }

      const uid = await dispatch('getUid')

      try {
        const batch = writeBatch(getFirestore())

        const data = {
          active: false,
          reviewedBy: uid,
          reviewed: new Date()
        }

        const errorRef = doc(getFirestore(), `errors/${errorId}`)
        batch.update(errorRef, data)

        await batch.commit()
          .then(async () => {
            data.errorId = errorId
            await commit('setErrorDone', data)
            commit('resetAppErrors')
          })

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:errorDone', params: { errorId } })
        return false
      }
    }
  },
  getters: {
    appErrors: s => s.appErrors,
    appErrorsArr: (s, getters) => (userId, location) => {
      if (getters.appErrorsReset) {
        //
      }

      let answer = []

      const allAppErrors = Object.keys(s.appErrors)
      if (allAppErrors.length) {
        answer = allAppErrors.slice(0)
      }

      answer = answer.filter(errorId => {
        return s.appErrors[errorId].active
      })

      if (userId) {
        answer = answer.filter(errorId => {
          return s.appErrors[errorId].owner === userId
        })
      }

      if (location) {
        answer = answer.filter(errorId => {
          return s.appErrors[errorId].location === location
        })
      }

      if (answer.length > 1) {
        answer.sort((a, b) => {
          let errorA = s.appErrors[a].timestamp ? +s.appErrors[a].timestamp : 0
          let errorB = s.appErrors[b].timestamp ? +s.appErrors[b].timestamp : 0

          if (errorA < errorB) { return -1 }
          if (errorA > errorB) { return 1 }
          return 0
        })
      }

      return answer
    },
    appArchievedErrorsArr: (s, getters) => (userId, location) => {
      if (getters.appErrorsReset) {
        //
      }

      let answer = []

      const allAppErrors = Object.keys(s.appErrors)
      if (allAppErrors.length) {
        answer = allAppErrors.slice(0)
      }

      answer = answer.filter(errorId => {
        return !s.appErrors[errorId].active
      })

      if (userId) {
        answer = answer.filter(errorId => {
          return s.appErrors[errorId].owner === userId
        })
      }

      if (location) {
        answer = answer.filter(errorId => {
          return s.appErrors[errorId].location === location
        })
      }

      if (answer.length > 1) {
        answer.sort((a, b) => {
          let errorA = s.appErrors[a].timestamp ? +s.appErrors[a].timestamp : 0
          let errorB = s.appErrors[b].timestamp ? +s.appErrors[b].timestamp : 0

          if (errorA < errorB) { return -1 }
          if (errorA > errorB) { return 1 }
          return 0
        })
      }

      return answer
    },
    allArchievedAppErrorsFetched: s => s.allArchievedAppErrorsFetched,
    appErrorsReset: s => s.appErrorsReset,
    lastFetchedArchievedError: s => s.lastFetchedArchievedError,
    unsubscribeToAppErrors: s => s.unsubscribeToAppErrors,
  }
}
