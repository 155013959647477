import { getFirestore, doc, collection, getDoc, getDocs, deleteDoc, query, where, limit, orderBy, writeBatch, increment, onSnapshot, setDoc, updateDoc } from 'firebase/firestore'
import { getStorage, ref, deleteObject, listAll } from 'firebase/storage'

export default {
  state: {
    userId: null,
    users: {},
    deleteUsers: {},
    usersReset: 0,
    unsubscribeDeleteUsers: null
  },
  mutations: {
    setUserId(state, value) {
      state.userId = value
    },
    setUserData(state, { uid, data, field }) {
      if (uid && data) {
        if (!state.users[uid]) { state.users[uid] = {} }

        if (field) {
          state.users[uid][field] = {}

          for (const key of Object.keys(data)) {
            state.users[uid][field][key] = data[key]
          }
        } else {
          for (const key of Object.keys(data)) {
            state.users[uid][key] = data[key]
          }
        }
      }
    },
    setUserDeleted(state, data) {
      if (data && data.userId) {

        if (data.userDeleted && data.userDeleted.toDate) {
          data.userDeleted = data.userDeleted.toDate()
        }

        if (!state.deleteUsers[data.userId]) { state.deleteUsers[data.userId] = {} }
        state.deleteUsers[data.userId].timestamp = data.userDeleted
      }
    },
    setUnsubscribeDeleteUsers(state, value) {
      state.unsubscribeDeleteUsers = value
    },
    resetUsers(state) {
      state.usersReset = +new Date()
    },
    setLastFetchedArchievedUserError(state, data) {
      if (data.userId) {
        if (!state.users[data.userId]) { state.users[data.userId] = {} }
        state.users[data.userId].lastFetchedArchievedError = data.error
      }
    },
    setLastFetchedArchievedUserFeedback(state, data) {
      if (data.userId) {
        if (!state.users[data.userId]) { state.users[data.userId] = {} }
        state.users[data.userId].lastFetchedArchievedFeedback = data.feedback
      }
    },
    setLastFetchedUserNews(state, data) {
      if (data.userId) {
        if (!state.users[data.userId]) { state.users[data.userId] = {} }
        state.users[data.userId].lastFetchedNews = data.news
      }
    },
    setAllArchievedUserAppErrorsFetched(state, userId) {
      if (userId) {
        if (!state.users[userId]) { state.users[userId] = {} }
        state.users[userId].allArchievedAppErrorsFetched = true
      }
    },
    setAllArchievedUserFeedbackFetched(state, userId) {
      if (userId) {
        if (!state.users[userId]) { state.users[userId] = {} }
        state.users[userId].allArchievedFeedbackFetched = true
      }
    },
    setAllUserNewsFetched(state, userId) {
      if (userId) {
        if (!state.users[userId]) { state.users[userId] = {} }
        state.users[userId].allNewsFetched = true
      }
    },
    deleteUserFromStore(state, userId) {
      if (userId) {
        if (state.deleteUsers[userId]) { delete state.deleteUsers[userId] }
        if (state.userId === userId) { state.userId = null }
        if (state.users[userId]) { delete state.users[userId] }
      }
    },
    clearInfo(state) {
      state.userId = null
      state.users = {}
      state.deleteUsers = {}
      state.unsubscribeDeleteUsers = null
    }
  },
  actions: {
    async fetchUserByEmail({ commit, dispatch }, email) {
      if (!email) {
        commit('setError', 'Не указан email')
        return false
      }

      try {
        const usersIds = []

        const ref = collection(getFirestore(), 'userProfile')
        const q = query(ref, where('email', '==', email))
        const users = await getDocs(q)

        if (users && !users.empty) {
          for (const user of users.docs) {
            if (!usersIds.includes(user.id)) { usersIds.push(user.id) }

            const data = { email }

            await commit('setUserData', { uid: user.id, data })
          }
        }

        return usersIds
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:fetchUserByEmail', params: { email } })
      }
    },
    async fetchUserById({ commit, dispatch }, uid) {
      try {
        if (!uid) {
          commit('setError', 'Не указан ID')
          return false
        }

        const data = {}

        const users = await getDoc(doc(getFirestore(), `userProfile/${uid}`))

        if (users.exists()) {
          const answer = users.data()
          if (answer.email) { data.email = answer.email }
        }

        await commit('setUserData', { uid, data })
        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:fetchUserById', params: { userId: uid } })
      }
    },
    async fetchUserTarif({ commit, dispatch, getters }, uid) {
      try {
        if (!uid) {
          commit('setError', 'Не указан uid')
          return false
        }

        const tarif = await getDoc(doc(getFirestore(), `userTarif/${uid}`))

        if (tarif && tarif.exists()) {
          if (tarif.exists()) {
            const answer = tarif.data()

            if (answer.timestamp && answer.timestamp.toDate) { answer.timestamp = answer.timestamp.toDate() }
            if (answer.validTill && answer.validTill.toDate) { answer.validTill = answer.validTill.toDate() }

            await commit('setUserData', { uid, data: answer, field: 'tarif' })

            if (answer.tarifId && !getters.tarifs[answer.tarifId]) {
              await dispatch('fetchTarif', answer.tarifId)
            }
          } else {
            await commit('setUserData', { uid, data: {}, field: 'tarif' })
          }
        }

        commit('resetUsers')
        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:fetchUserTarif', params: { uid } })
      }
    },
    async fetchUserSettings({ commit, dispatch }, uid) {
      try {
        if (!uid) {
          commit('setError', 'Не указан uid')
          return false
        }

        const data = {
          defaultCurrency: null,
          locale: null
        }

        const userSettings = await getDoc(doc(getFirestore(), `userSettings/${uid}`))

        if (userSettings && userSettings.exists()) {
          const answer = userSettings.data()
          data.defaultCurrency = answer.defaultCurrency
          data.locale = answer.locale
        }

        await commit('setUserData', { uid, data })
        commit('resetUsers')
        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:fetchUserSettings', params: { uid } })
      }
    },
    async fetchUserProfile({ commit, dispatch }, uid) {
      try {
        if (!uid) {
          commit('setError', 'Не указан uid')
          return false
        }

        const data = {
          name: null,
          userPic: null
        }

        const profile = await getDoc(doc(getFirestore(), `userProfile/${uid}`))

        if (profile && profile.exists()) {
          const answer = profile.data()
          data.name = answer.name
          data.userPic = answer.userPic
        }

        await commit('setUserData', { uid, data })
        commit('resetUsers')
        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:fetchUserStats', params: { uid } })
      }
    },
    async fetchUserStats({ commit, dispatch }, uid) {
      try {
        if (!uid) {
          commit('setError', 'Не указан uid')
          return false
        }

        let data = {}

        const stats = await getDoc(doc(getFirestore(), `userStats/${uid}`))

        if (stats && stats.exists()) {
          const answer = stats.data()

          if (answer.newsRead && answer.newsRead.toDate) {
            answer.newsRead = await answer.newsRead.toDate()
          }

          data = answer
        }

        await commit('setUserData', { uid, data, field: 'stats' })
        commit('resetUsers')
        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:fetchUserStats', params: { uid } })
      }
    },
    async fetchUserDeleted({ commit, dispatch }, uid) {
      try {
        if (!uid) {
          commit('setError', 'Не указан uid')
          return false
        }

        const data = {
          userId: uid,
          userDeleted: false
        }

        const userDeleted = await getDoc(doc(getFirestore(), `deleteUsers/${uid}`))

        if (userDeleted && userDeleted.exists()) {
          const receivedData = userDeleted.data()

          if (receivedData.timestamp && receivedData.timestamp.toDate) {
            receivedData.timestamp = receivedData.timestamp.toDate()
          }

          data.userDeleted = receivedData.timestamp
        }

        await commit('setUserDeleted', data)
        commit('resetUsers')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:fetchUserDeleted', params: { userId: uid } })
      }
    },
    async subscribeDeleteUsers({ commit, dispatch }) {
      try {
        const ref = collection(getFirestore(), 'deleteUsers')
        const q = query(ref, orderBy('timestamp', 'asc'))

        const unsubscribe = onSnapshot(q, async querySnapshot => {
          querySnapshot.docChanges().forEach(async change => {
            if (change.type === 'added') {
              const data = change.doc.data()

              await commit('setUserDeleted', {
                userId: change.doc.id,
                userDeleted: data.timestamp
              })
            } else if (change.type === 'removed') {
              await commit('deleteUserFromStore', change.doc.id)
            }
          })
          commit('resetUsers')
        })

        await commit('setUnsubscribeDeleteUsers', unsubscribe)
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:subscribeDeleteUsers', params: {} })
      }
    },
    async unsubscribeDeleteUsers({ dispatch, getters }) {
      try {
        const unsubscribeDeleteUsers = getters.unsubscribeDeleteUsers
        if (unsubscribeDeleteUsers) {
          await unsubscribeDeleteUsers()
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:unsubscribeDeleteUsers', params: {} })
        return false
      }
    },
    async deleteUserData({ commit, dispatch, getters }, data) {
      try {
        if (!data || !data.userId) {
          commit('setError', 'Ошибка в переданных данных')
          return
        }

        if (getters.firebaseQuotaReached) {
          commit('setError', 'Ошибка: достигнут лимит Firebase')
          return
        }

        let readLimit = 1000
        if (data.readLimit) { readLimit = data.readLimit }

        let deleteLimit = 1000
        if (data.deleteLimit) { deleteLimit = data.deleteLimit }

        if (!readLimit || deleteLimit <= 0) { return }

        const startedAt = Date.now()

        console.log('Started deleting user: ' + data.userId)

        const blocks = [
          { name: 'news', userIdField: 'user' },
          { name: 'moneyBoxes', userIdField: 'owner' },
          { name: 'spreads', userIdField: 'owner' },
          { name: 'tagsCollections', userIdField: 'owner' },
          { name: 'transactions', userIdField: 'owner' },
          { name: 'accounts', userIdField: 'owner' },
          { name: 'banks', userIdField: 'owner' },
          { name: 'cards', userIdField: 'owner' },
          { name: 'tags', userIdField: 'owner' },
          { name: 'tagsSums', userIdField: 'owner' },
          { name: 'logs', userIdField: 'owner' },
          { name: 'tarifs', userIdField: 'owner' },
          { name: 'userDefaultAccount' },
          { name: 'userSettings' },
          { name: 'userStats' },
          { name: 'userTarif' },
          { name: 'userProfile' },
          { name: 'payments', userIdField: 'owner' },
        ]

        for (let i = 0; i < blocks.length; i++) {
          blocks[i].idx = i + 1
        }

        let finished = false

        for (const block of blocks) {
          if (!block.name) {
            console.log('no block name provided')
            continue
          }

          if (!block.userIdField) {
            if (deleteLimit > 0 && readLimit > 0) {
              if (block.name === 'userProfile') {
                const deletedUserData = {
                  deletedAdminUid: await dispatch('getUid'),
                  deletedTimestamp: new Date()
                }

                const user = await getDoc(doc(getFirestore(), `userProfile/${data.userId}`))
                readLimit -= 1

                if (user && user.exists()) {
                  deletedUserData.email = user.data().email
                }

                const deletedUsersRef = doc(getFirestore(), `deletedUsers/${data.userId}`)
                await setDoc(deletedUsersRef, deletedUserData)
              } else if (block.name === 'userSettings') {
                const data = await getDoc(doc(getFirestore(), `userSettings/${data.userId}`))
                readLimit -= 1

                if (data && data.exists()) {
                  const country = data.data().country

                  if (country) {
                    const statsRef = doc(getFirestore(), 'stats/countries')
                    await updateDoc(statsRef, {
                      [country]: increment(-1)
                    })
                  }
                }
              }

              await deleteDoc(doc(getFirestore(), `${block.name}/${data.userId}`))
              deleteLimit -= 1
            }
          } else {
            if (deleteLimit > 0 && readLimit > 0) {
              console.log(`  deleting ${block.name} (${block.idx}/${blocks.length})`)

              const blockRef = collection(getFirestore(), block.name)
              const blockQ = query(blockRef, limit(readLimit), where(block.userIdField, '==', data.userId))
              const receivedDocs = await getDocs(blockQ)
              if (!receivedDocs || receivedDocs.empty) { continue }

              console.log(`      recieved ${receivedDocs.size} docs`)
              readLimit -= receivedDocs.size

              for (const oneDoc of receivedDocs.docs) {
                await deleteDoc(doc(getFirestore(), `${block.name}/${oneDoc.id}`))
                deleteLimit -= 1
              }
            }
          }

          if (!readLimit <= 0 || deleteLimit <= 0) { break }
        }

        const storage = getStorage()

        const profileFolderRef = ref(storage, `users/${data.userId}`)
        const profileFilesList = await listAll(profileFolderRef)
        const profileFile = profileFilesList.items
        if (profileFile && profileFile.length) {
          for (const profileFileRef of profileFile) {
            await deleteObject(profileFileRef)
          }
        }

        const receiptsFolderRef = ref(storage, `users/${data.userId}/receipts`)
        const receiptsFilesList = await listAll(receiptsFolderRef)
        const receiptsFile = receiptsFilesList.items
        if (receiptsFile && receiptsFile.length) {
          for (const receiptsFileRef of receiptsFile) {
            await deleteObject(receiptsFileRef)
          }
        }

        const statsRef = doc(getFirestore(), 'stats/users')
        await updateDoc(statsRef, {
          numberOfUsersTotal: increment(-1)
        })

        if (deleteLimit > 0) {
          const deleteUsersRef = doc(getFirestore(), `deleteUsers/${data.userId}`)
          await deleteDoc(deleteUsersRef)
          deleteLimit -= 1
          finished = true

          await commit('deleteUserFromStore', data.userId)

          const numberOfUsersTotal = getters.numberOfUsersTotal
          await commit('setNumberOfUsersTotal', (numberOfUsersTotal - 1))
        }

        if (finished) {
          console.log('user deleted')
        } else {
          console.log('user deleted partly')
        }

        console.log(`Finished in ${Date.now() - startedAt} seconds.`)

        commit('resetUsers')
        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:deleteUserData', params: { userId: data.userId } })
      }
    },
    async saveUserTarif({ commit, dispatch }, { userId, tarif, validTill, personalTarif }) {
      if (!userId || !tarif || !validTill) {
        commit('setError', 'Ошибка')
        return false
      }

      const syncTimestamp = new Date()

      const uid = await dispatch('getUid')

      let newTarifData

      try {
        const batch = writeBatch(getFirestore())

        if (tarif === 'TarifPersonal' || personalTarif) {
          tarif = doc(collection(getFirestore(), 'tarifs')).id
        }

        const data = {
          paymentId: 'createdByAdmin:' + uid,
          tarifId: tarif,
          validTill: validTill,
          timestamp: syncTimestamp
        }

        if (personalTarif) {
          const tarifsRef = doc(getFirestore(), `tarifs/${tarif}`)

          const allKeys = Object.keys(personalTarif)
          for (const key of allKeys) {
            if (personalTarif[key] === false) {
              delete personalTarif[key]
            } else {
              if (personalTarif[key] !== true && personalTarif[key] !== 'unlimited') {
                personalTarif[key] = +personalTarif[key]
              }
            }
          }

          newTarifData = {
            active: true,
            limits: personalTarif,
            nameKey: 'TarifPersonal',
            public: false,
            owner: userId,
            timestamp: syncTimestamp,
            creator: uid
          }
          batch.set(tarifsRef, newTarifData)
        }

        const tarifRef = doc(getFirestore(), `userTarif/${userId}`)
        batch.update(tarifRef, data)

        const tarifLogId = doc(collection(getFirestore(), `logs`)).id
        const tarifLogRef = doc(getFirestore(), `logs/${tarifLogId}`)

        batch.set(tarifLogRef, {
          timestamp: syncTimestamp,
          place: 'tarif',
          action: 'edited',
          type: 'sync',
          id: tarif,
          owner: userId,
          data: {
            creator: 'admin',
            creatorId: uid,
            info: data
          }
        })

        await batch.commit()

        await commit('setUserData', { uid: userId, data, field: 'tarif' })

        if (newTarifData) {
          await commit('setTarif', { tarifId: tarif, tarif: newTarifData })
        }

        commit('resetUsers')

        return tarif
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:saveUserTarif', params: { userId, tarif, validTill } })
        return false
      }
    },
    async logoutOtherSessions({ commit, dispatch }, userId) {
      if (!userId) {
        commit('setError', 'Ошибка')
        return false
      }

      const uid = await dispatch('getUid')

      const syncTimestamp = new Date()

      try {
        const logId = doc(collection(getFirestore(), `logs`)).id
        const logRef = doc(getFirestore(), `logs/${logId}`)

        await setDoc(logRef, {
          timestamp: syncTimestamp,
          place: 'auth',
          action: 'logout',
          type: 'sync',
          owner: userId,
          id: 'adminLogout',
          data: {
            admin: uid
          }
        })

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:logoutOtherSessions', params: { userId } })
        return false
      }
    }
  },
  getters: {
    userId: s => s.userId,
    users: s => s.users,
    deletedUsersArr: (s, getters) => {
      if (getters.usersReset) {
        //
      }

      let answer = []

      const allDeletedUsers = Object.keys(s.deleteUsers)
      if (allDeletedUsers.length) {
        answer = allDeletedUsers.slice(0)
      }

      if (answer.length > 1) {
        answer.sort((a, b) => {
          let dateA = s.deleteUsers[a].timestamt ? +s.deleteUsers[a].timestamt : 0
          let dateB = s.deleteUsers[b].timestamt ? +s.deleteUsers[b].timestamt : 0

          if (dateA < dateB) { return -1 }
          if (dateA > dateB) { return 1 }
          return 0
        })
      }

      return answer
    },
    usersReset: s => s.usersReset,
    deleteUsers: s => s.deleteUsers,
    unsubscribeDeleteUsers: s => s.unsubscribeDeleteUsers
  }
}
