import { getAnalytics, logEvent } from 'firebase/analytics'

export default {
  actions: {
    logEvent({ dispatch }, { eventName = null, eventParams = null }) {
      if (!eventName) { return }

      try {
        if (eventParams) {
          logEvent(getAnalytics(), eventName, eventParams)
        } else {
          logEvent(getAnalytics(), eventName)
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'logEvent', params: { eventName: eventName, eventParams: eventParams } })
        return false
      }
    }
  }
}