import { getFirestore, collection, doc, getDocs, query, where, limit, orderBy, startAfter, writeBatch, onSnapshot } from 'firebase/firestore'

export default {
  state: {
    feedback: {},
    feedbackReset: 0,
    allArchievedFeedbackFetched: false,
    lastFetchedArchievedFeedback: null,
    unsubscribeToFeedback: null
  },
  mutations: {
    setFeedback(state, feedbackInfo) {
      if (feedbackInfo && feedbackInfo.feedbackId) {
        if (feedbackInfo.timestamp && feedbackInfo.timestamp.toDate) {
          feedbackInfo.timestamp = feedbackInfo.timestamp.toDate()
        }
        state.feedback[feedbackInfo.feedbackId] = feedbackInfo
      }
    },
    setUnsubscribeToFeedback(state, value) {
      state.unsubscribeToFeedback = value
    },
    resetFeedback(state) {
      state.feedbackReset = +new Date()
    },
    setAllArchievedFeedbackFetched(state) {
      state.allArchievedFeedbackFetched = true
    },
    setLastFetchedArchievedFeedback(state, feedback) {
      state.lastFetchedArchievedFeedback = feedback
    },
    deleteFeedbackStore(state, feedbackId) {
      if (feedbackId && state.feedback[feedbackId]) {
        delete state.feedback[feedbackId]
      }
    },
    setFeedbackDone(state, data) {
      if (data && data.feedbackId && state.feedback[data.feedbackId]) {
        state.feedback[data.feedbackId].active = false
        state.feedback[data.feedbackId].reviewedBy = data.reviewedBy
        state.feedback[data.feedbackId].reviewedTimestamp = data.reviewedTimestamp
      }
    },
    clearInfo(state) {
      state.feedback = {}
      state.allArchievedFeedbackFetched = false
      state.lastFetchedArchievedFeedback = null
      state.unsubscribeToFeedback = null
    }
  },
  actions: {
    async subscribeToFeedback({ commit, dispatch }) {
      try {
        const ref = collection(getFirestore(), 'feedback')
        const q = query(ref, where('active', '==', true), orderBy('timestamp', 'desc'))

        const unsubscribe = onSnapshot(q, async querySnapshot => {
          querySnapshot.docChanges().forEach(async change => {
            const data = change.doc.data()
            data.feedbackId = change.doc.id

            if (change.type === 'added' || change.type === 'modified') {
              await commit('setFeedback', data)
            } else if (change.type === 'removed') {
              data.active = false
              await commit('setFeedback', data)
            }
          })
          commit('resetFeedback')
        })

        await commit('setUnsubscribeToFeedback', unsubscribe)
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:subscribeToFeedback', params: {} })
      }
    },
    async unsubscribeToFeedback({ dispatch, getters }) {
      try {
        const unsubscribeToFeedback = getters.unsubscribeToFeedback
        if (unsubscribeToFeedback) {
          await unsubscribeToFeedback()
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:unsubscribeToFeedback', params: {} })
        return false
      }
    },
    async fetchArchievedFeedback({ commit, dispatch, getters }, userId) {
      if (userId) {
        if (getters.users[userId] && getters.users[userId].allArchievedFeedbackFetched) { return }
      } else {
        if (getters.allArchievedFeedbackFetched) { return }
      }

      const maxLength = 10

      try {
        const queries = [limit(maxLength), orderBy('timestamp', 'desc'), where('active', '==', false)]

        let lastFetchedArchievedFeedback
        if (userId) {
          queries.push(where('owner', '==', userId))

          if (getters.users[userId] && getters.users[userId].lastFetchedArchievedFeedback) {
            lastFetchedArchievedFeedback = getters.users[userId].lastFetchedArchievedFeedback
          }
        } else {
          lastFetchedArchievedFeedback = getters.lastFetchedArchievedFeedback
        }

        if (lastFetchedArchievedFeedback) {
          queries.push(startAfter(lastFetchedArchievedFeedback))
        }

        const feedbackRef = collection(getFirestore(), 'feedback')
        const q = query(feedbackRef, ...queries)
        await getDocs(q)
          .then(async feedbacks => {
            feedbacks.forEach(async feedback => {
              let data = feedback.data()

              if (data.timestamp && data.timestamp.toDate) { data.timestamp = data.timestamp.toDate() }
              if (data.reviewedTimestamp && data.reviewedTimestamp.toDate) { data.reviewedTimestamp = data.reviewedTimestamp.toDate() }

              data.feedbackId = feedback.id
              await commit('setFeedback', data)
            })

            if (userId) {
              const data = {
                userId,
                feedback: feedbacks.docs[feedbacks.docs.length - 1]
              }
              await commit('setLastFetchedArchievedUserFeedback', data)
            } else {
              await commit('setLastFetchedArchievedFeedback', feedbacks.docs[feedbacks.docs.length - 1])
            }

            if (feedbacks.empty || feedbacks.size < maxLength) {
              if (userId) {
                await commit('setAllArchievedUserFeedbackFetched', userId)
              } else {
                await commit('setAllArchievedFeedbackFetched')
              }
            }

            commit('resetFeedback')
          })
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:fetchArchievedFeedback', params: { userId } })
      }
    },
    async feedbackDone({ commit, dispatch, getters }, feedbackId) {
      if (!feedbackId || !getters.feedback[feedbackId]) {
        commit('setError', 'Ошибка')
        return false
      }

      const uid = await dispatch('getUid')

      try {
        const batch = writeBatch(getFirestore())

        const data = {
          active: false,
          reviewedBy: uid,
          reviewedTimestamp: new Date()
        }

        const feedbackRef = doc(getFirestore(), `feedback/${feedbackId}`)
        batch.update(feedbackRef, data)

        await batch.commit()
          .then(async () => {
            data.feedbackId = feedbackId
            await commit('setFeedbackDone', data)
            commit('resetFeedback')
          })

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:feedbackDone', params: { feedbackId } })
        return false
      }
    }
  },
  getters: {
    feedback: s => s.feedback,
    feedbackArr: (s, getters) => userId => {
      if (getters.feedbackReset) {
        //
      }

      let answer = []

      const allFeedback = Object.keys(s.feedback)
      if (allFeedback.length) {
        answer = allFeedback.slice(0)
      }

      answer = answer.filter(feedbackId => {
        return s.feedback[feedbackId].active
      })

      if (userId) {
        answer = answer.filter(feedbackId => {
          return s.feedback[feedbackId].owner === userId
        })
      }

      if (answer.length > 1) {
        answer.sort((a, b) => {
          let feedbackA = s.feedback[a].timestamt ? +s.feedback[a].timestamt : 0
          let feedbackB = s.feedback[b].timestamt ? +s.feedback[b].timestamt : 0

          if (feedbackA < feedbackB) { return -1 }
          if (feedbackA > feedbackB) { return 1 }
          return 0
        })
      }

      return answer
    },
    archievedFeedbackArr: (s, getters) => userId => {
      if (getters.feedbackReset) {
        //
      }

      let answer = []

      const allFeedback = Object.keys(s.feedback)
      if (allFeedback.length) {
        answer = allFeedback.slice(0)
      }

      answer = answer.filter(feedbackId => {
        return !s.feedback[feedbackId].active
      })

      if (userId) {
        answer = answer.filter(feedbackId => {
          return s.feedback[feedbackId].owner === userId
        })
      }

      if (answer.length > 1) {
        answer.sort((a, b) => {
          let feedbackA = s.feedback[a].timestamt ? +s.feedback[a].timestamt : 0
          let feedbackB = s.feedback[b].timestamt ? +s.feedback[b].timestamt : 0

          if (feedbackA < feedbackB) { return -1 }
          if (feedbackA > feedbackB) { return 1 }
          return 0
        })
      }

      return answer
    },
    allArchievedFeedbackFetched: s => s.allArchievedFeedbackFetched,
    feedbackReset: s => s.feedbackReset,
    lastFetchedArchievedFeedback: s => s.allArchievedFeedbackFetched,
    unsubscribeToFeedback: s => s.unsubscribeToFeedback
  }
}
