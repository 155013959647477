import { getFirestore, doc, collection, query, getDoc, getDocs, where, orderBy } from 'firebase/firestore'

export default {
  state: {
    tarifs: {}
  },
  mutations: {
    setTarif(state, data) {
      state.tarifs[data.tarifId] = data.tarif
    },
    clearInfo(state) {
      state.tarifs = {}
    }
  },
  actions: {
    async fetchTarifs({ commit, dispatch }) {
      try {
        const ref = collection(getFirestore(), 'tarifs')
        const q = query(ref, where('public', '==', true), where('active', '==', true), orderBy('timestamp', 'asc'))
        await getDocs(q)
          .then(async tarifs => {
            tarifs.forEach(async tarif => {
              const answer = tarif.data()
              if (answer && answer.timestamp && answer.timestamp.toDate) { answer.timestamp = answer.timestamp.toDate() }
              await commit('setTarif', { tarifId: tarif.id, tarif: answer })
            })
          })
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:fetchTarifs', params: {} })
      }
    },
    async fetchTarif({ commit, dispatch }, tarifId) {
      if (!tarifId) {
        commit('setError', 'Не указан tarifId')
        return false
      }

      try {
        await getDoc(doc(getFirestore(), `tarifs/${tarifId}`))
          .then(async tarif => {
            if (tarif.exists()) {
              const answer = tarif.data()
              await commit('setTarif', { tarifId, tarif: answer })
            }
          })
        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'admin:fetchTarif', params: { tarifId } })
      }
    }
  },
  getters: {
    tarifs: s => s.tarifs,
    tarifsArr: (s, getters) => {
      let answer = Object.keys(getters.tarifs)

      answer = answer.filter(tarifId => {
        return getters.tarifs[tarifId].public
      })

      if (answer.length > 1) {
        answer.sort((a, b) => {
          let tarifA = s.tarifs[a].nameKey ? s.tarifs[a].nameKey : a
          let tarifB = s.tarifs[b].nameKey ? s.tarifs[b].nameKey : b

          if (tarifA > tarifB) { return -1 }
          if (tarifA < tarifB) { return 1 }
          return 0
        })
      }

      return answer
    }
  }
}
