<template>
  <div class="loading loading--full-heigth" />
</template>

<script>
export default {
  name: 'LoaderComponent',
}
</script>

<style scoped>
  .loading {
    display: flex;
    justify-content: center;
    opacity: .7;
  }

  .loading--full-heigth {
    align-items: center;
    height: 100%;
  }

  .loading::after {
    content: "";
    width: 100px;
    height: 100px;
    border: 10px solid #F3F4F6;
    border-top-color: #34D399;
    border-radius: 50%;
    animation: loading 1s ease infinite;
  }

  @keyframes loading {
    to {
      transform: rotate(1turn)
    }
  }
</style>