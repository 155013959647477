import { getFirestore, collection, addDoc } from 'firebase/firestore'

export default {
  state: {
    error: null
  },
  mutations: {
    setError(state, error) {
      const errorCodes = {
        'auth/user-not-found': 'Пользователь с таким email не зарегистрирован',
        'auth/wrong-password': 'Неверный пароль',
        'auth/email-already-in-use': 'Такой email уже зарегистрирован',
        'auth/invalid-email': 'Проверьте привальность написания email',
        'auth/argument-error': 'Проверьте правильность заполнения полей',
        'auth/too-many-requests': 'Слишком много запросов. Попробуйте позже',
        'permission-denied': 'Нет доступа',
        'unavailable': 'Нет доступа к интернету'
      }

      if (error.code) {
        if (error.code === 'resource-exhausted') {
          state.firebaseQuotaReached = true
        }

        if (errorCodes[error.code]) {
          state.error = errorCodes[error.code]
        } else {
          state.error = 'Ошибка'
        }
      } else {
        state.error = error
      }
    },
    clearError(state) {
      state.error = null
    },
    clearInfo(state) {
      state.error = null
    }
  },
  actions: {
    async saveErrorInfo({ commit, dispatch, getters }, errorInfo) {
      if (!errorInfo) { return }

      commit('setError', errorInfo.error)

      console.log('[', errorInfo.location, ']: ', errorInfo.error)

      if (!getters.online) { return }

      try {
        const userId = await dispatch('getUid')

        if (errorInfo.error.code && errorInfo.error.code === 'unavailable') { return }

        await addDoc(collection(getFirestore(), 'errors'), {
          timestamp: new Date(),
          error: errorInfo.error + '',
          location: errorInfo.location,
          owner: userId,
          params: errorInfo.params || {},
          active: true
        })
      } catch (e) {
        console.log(`[saveErrorInfo]: ${e}`)
      }
    }
  },
  getters: {
    error: s => s.error
  }
}
