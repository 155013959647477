<template>
  <div>
    <nav
      class="bg-white z-30 pt-4 pb-2 px-6 fixed inset-0 w-60 overflow-y-auto flex flex-col content-between transform transition-transform duration-300 ease-in"
      :class="{'-translate-x-60': !menuIsOpen, 'translate-x-0': menuIsOpen}"
    >
      <div class="text-gray-600 text-xl transition-opacity mb-1 h-full">
        <div v-if="online">
          <div
            v-for="link in links"
            :key="link.name"
            class="mb-2"
          >
            <router-link
              class="hover:opacity-70"
              :class="{'cursor-default': $route.name === link.name}"
              active-class="opacity-50"
              :to="{ name: link.name }"
              :exact="link.exact"
            >
              <span @click="closeMenu()">{{ link.title }}</span>
            </router-link>
          </div>
        </div>

        <span
          class="cursor-pointer hover:opacity-70"
          @click.prevent="logout()"
        >
          Выйти
        </span>
      </div>

      <div class="text-xs mt-4 sm:mt-2">
        <p
          class="mt-2 text-gray-400 text-center"
        >
          v{{ appVersion }}
        </p>
      </div>
    </nav>

    <div
      v-if="menuIsOpen"
      class="z-20 fixed inset-0 bg-gray-700 opacity-50"
      @click="closeMenu()"
    />
  </div>
</template>

<script>
export default {
  name: 'DrawerNav',
  data: () => ({
    links: [
      { title: 'Главная', name: 'Dashboard', exact: true },
      { title: 'Пользователи', name: 'UsersView' },
      { title: 'Ошибки', name: 'AppErrors' },
      { title: 'Удаление пользователей', name: 'DeleteUsers' },
      { title: 'Обратная связь', name: 'Feedback' },
      { title: 'Новости', name: 'News' }
    ],
    appVersion: null
  }),
  computed: {
    menuIsOpen () {
      return this.$store.getters.menuIsOpen
    },
    online () {
      return this.$store.getters.online
    }
  },
  created () {
    this.appVersion = this.$store.getters.appVersion
  },
  methods: {
    closeMenu() {
      this.$store.commit('setMenuIsOpen', false)
    },
    async logout() {
      this.closeMenu()
      await this.$store.dispatch('logout')
      this.$router.push({ name: 'Login' }).catch(() => {})
    }
  }
}
</script>